<template>
  <div class="dashboard-widget-settings">
    <el-form
      label-position="left"
      label-width="130px"
      style="width: 100%"
      size="mini"
    >
      <el-form-item label="Размер текста">
        <el-slider v-model="localValue.fontSize" :min="1" :max="200" show-input input-size="mini"></el-slider>
      </el-form-item>
      <el-form-item label="Цвет текста">
        <el-color-picker v-model="localValue.color"></el-color-picker>
      </el-form-item>
      <el-form-item label="Padding">
        <el-slider v-model="localValue.padding" :min="0" :max="300" show-input input-size="mini"></el-slider>
      </el-form-item>
      <el-form-item label="Текст">
        <wysiwyg
          v-model="localValue.text"
        ></wysiwyg>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'ButtonWidgetSettings',
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  computed: {
    localValue: {
      get() {return this.value},
      set(value) {this.$emit('input', value)}
    }
  }
}
</script>